import { graphql } from 'gatsby';
import { Box, Paragraph } from 'theme-ui';
import LayoutGrid from './Layout/LayoutGrid';
import Link from './Link';
import { Subtitle, Title } from './SectionHeader';

export default function PageNavigator({ previous, next, ...rest }) {
  if (!previous && !next) return null;
  return (
    <LayoutGrid
      sx={{
        gridColumn: 'full',
        gridTemplateRows: [
          `[next-start] auto [next-end previous-start] auto [previous-end]`,
          null,
          `[previous-start next-start] 1fr [previous-end next-end]`,
        ],
        backgroundColor: 'white',
        '::before': {
          content: '""',
          display: 'block',
          backgroundColor: 'primary.100',
          gridRow: 'previous',
          gridColumn: 'left',
        },
      }}
      {...rest}
    >
      <Link
        to={next?.to || '/'}
        sx={{
          gridRow: 'next',
          gridColumn: 'middle-start / content-end',
          paddingX: [3, 4],
          paddingY: [5, null, 6],
          marginY: 'auto',
          color: 'inherit',
          h2: { transition: 'color' },
          ':hover h2': { color: 'primary.700' },
        }}
      >
        {next ? (
          <Box>
            <Subtitle>Nächste Seite:</Subtitle>
            <Title as="h2" sx={{ paddingY: 2 }}>
              {next.target?.title}
            </Title>
            {next.target?.description && (
              <Paragraph>{next.target?.description}</Paragraph>
            )}
          </Box>
        ) : (
          <Box>
            <Title as="h2" sx={{ paddingY: 2 }}>
              Zur Startseite
            </Title>
          </Box>
        )}
      </Link>

      {previous && (
        <Link
          to={previous.to}
          sx={{
            gridRow: 'previous',
            gridColumn: 'content-start / left-end',
            paddingX: [3, 4],
            paddingY: 4,
            marginY: 'auto',
            color: 'primary.900',
            h3: { transition: 'color' },
            ':hover h3': { color: 'primary.600' },
          }}
        >
          <Box>
            <Subtitle>Vorige Seite:</Subtitle>
            <Title as="h3" sx={{ paddingY: 2 }}>
              {previous.target?.title}
            </Title>
          </Box>
        </Link>
      )}
    </LayoutGrid>
  );
}

export const fragment = graphql`
  fragment PageNavigator on SanityMenuItem {
    target {
      ... on SanityPage {
        title
        description
      }
    }
  }
`;
