import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import { Box, Flex } from 'theme-ui';
import clientConfig from '../../client-config';

export default function SanityFigure({
  asset,
  alt,
  caption,
  href,
  className,
  maxWidth, // Used by FigureSerializer to constrain the width to the content area.
  // maxHeight: _,
  // ...rest
}) {
  if (!asset) return null;
  return (
    <Figure className={className}>
      <Image
        asset={asset}
        alt={alt}
        href={href}
        sx={{ maxWidth }}
        maxWidth={maxWidth}
      />
      <Caption>{caption}</Caption>
    </Figure>
  );
}

export function Figure({ children, ...rest }) {
  return (
    <Flex as="figure" {...rest}>
      {children}
    </Flex>
  );
}

export function Image({ asset, alt, maxWidth, href, ...rest }) {
  if (!asset) return null;
  if (href) {
    return (
      <a href={href} sx={{ marginX: 'auto' }}>
        <Image asset={asset} alt={alt} maxWidth={maxWidth} {...rest} />
      </a>
    );
  }
  const imageData = getGatsbyImageData(
    asset,
    { maxWidth },
    clientConfig.sanity
  );
  return (
    <GatsbyImage
      image={imageData}
      alt={alt}
      sx={{ height: '100%', marginX: 'auto' }}
      {...rest}
    />
  );
}

export function Caption({ children, ...rest }) {
  if (!children) return null;
  return (
    <Box as="figcaption" {...rest}>
      {children}
    </Box>
  );
}

export const fragment = graphql`
  fragment SanityFigure on SanityFigure {
    asset: _rawAsset(resolveReferences: { maxDepth: 10 })
    caption
    alt
  }
`;
