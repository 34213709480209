import { Box } from '@theme-ui/components';
import { graphql } from 'gatsby';
import BlockContent from '../components/BlockContent';
import Layout from '../components/Layout';
import OfferSection from '../components/OfferSection';
import PageNavigator from '../components/PageNavigator';
import Seo from '../components/SEO';
import useMenu from '../useMainMenu';

export default function SanityPage({ data, path }) {
  const { page } = data;
  const { getNext, getPrevious } = useMenu();
  return (
    <Layout pageId={page?.id}>
      <Seo title={page?.title} />
      <BlockContent blocks={page?.blocks} />
      {path === '/' ? (
        <>
          <Box sx={{ gridColumn: 'full', paddingBottom: 6 }} /> {/* Fix for static rendering issue */}
          <OfferSection />
          <PageNavigator
            previous={getPrevious(page?.id)}
            next={getNext(page?.id)}
          />
        </>
      ) : (
        <>
          <PageNavigator
            previous={getPrevious(page?.id)}
            next={getNext(page?.id)}
          />
          <OfferSection />
        </>
      )}
    </Layout>
  );
}

export const query = graphql`
  query PageTemplate($slug__current: String!) {
    page: sanityPage(slug: { current: { eq: $slug__current } }) {
      id
      title
      blocks: _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`;
