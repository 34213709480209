import { Box } from 'theme-ui';

export default function ListSerializer({ type, children }) {
  const as = type === 'bullet' ? 'ul' : 'ol';
  return (
    <Box as={as} variant={`styles.${as}`}>
      {children}
    </Box>
  );
}
