import { Box, Heading, Paragraph } from 'theme-ui';

export default function BlockSerializer({ node, children }) {
  const { style = 'normal' } = node;

  if (/^h\d/.test(style)) {
    return (
      <Heading variant={`styles.${style}`} sx={{ marginX: [3, 4] }} as={style}>
        {children}
      </Heading>
    );
  }

  if (style === 'blockquote') {
    return (
      <Box as="blockquote" variant="styles.blockquote" sx={{ marginX: [3, 4] }}>
        {children}
      </Box>
    );
  }

  return <Paragraph sx={{ marginX: [3, 4] }}>{children}</Paragraph>;
}
