import BaseBlockContent from '@sanity/block-content-to-react';
import clientConfig from '../../client-config';
import serializers from './serializers';

export default function BlockContent({ blocks, ...rest }) {
  return (
    <BaseBlockContent
      blocks={blocks}
      serializers={serializers}
      {...clientConfig.sanity}
      {...rest}
    />
  );
}
