import { Paragraph } from 'theme-ui';
import LayoutGrid from '../Layout/LayoutGrid';

const margins = { large: 6, regular: 5, small: 4, none: 0, undefined: 0 };

export default function DiscretionSerializer({ node }) {
  return (
    <LayoutGrid
      sx={{
        gridColumn: 'full',
        backgroundColor: 'accent.200',
        color: 'accent.200.contrast',
        marginTop: margins[node.marginTop],
        marginBottom: margins[node.marginBottom],
        paddingTop: [4, 6],
        paddingBottom: [6, 6.5],
      }}
    >
      <Paragraph variant="discretion" sx={{ marginX: [3, 4] }}>
        {node.text ||
          'Laut Gesetz, aufgrund meiner inneren Überzeugung und meiner Profession als Psychotherapeutin bin ich zu absoluter Verschwiegenheit verpflichtet.'}
      </Paragraph>
    </LayoutGrid>
  );
}
