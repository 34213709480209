import SectionHeader, { Subtitle, Title } from './SectionHeader';

export default function SanityTitle({ title, subtitle }) {
  return (
    <SectionHeader sx={{ marginTop: [5, null, 6], marginBottom: [3, null, 4] }}>
      <Title
        sx={{
          textAlign: 'center',
          marginBottom: [3, null, 4],
        }}
      >
        {title}
      </Title>
      <Subtitle
        sx={{
          textAlign: 'center',
          marginY: [3, null, 4],
        }}
      >
        {subtitle}
      </Subtitle>
    </SectionHeader>
  );
}
