import React from 'react';
import { Box, Heading, Paragraph, Text } from 'theme-ui';
import LayoutGrid from '../Layout/LayoutGrid';

const margins = { regular: 4, overlap: -5, none: 0, undefined: 4 };

export default function CostsSerializer({ node }) {
  return (
    <LayoutGrid
      sx={{
        gridColumn: 'full',
        marginTop: margins[node.marginTop],
        marginBottom: margins[node.marginBottom],
      }}
    >
      {node?.variants?.length === 1 ? (
        node.showRefunds ? (
          <SingleVariantWithRefunds
            variant={node.variants[0]}
            size={node.size}
          />
        ) : (
          <SingleVariant variant={node.variants[0]} size={node.size} />
        )
      ) : node.showRefunds ? (
        <TwoVariantsWithRefunds variants={node.variants} size={node.size} />
      ) : (
        <TwoVariants variants={node.variants} size={node.size} />
      )}
    </LayoutGrid>
  );
}

function Lines({ children }) {
  return (
    <React.Fragment>
      {children.split('\n').map((line, index, { length }) => (
        <React.Fragment key={index}>
          <Text>{line}</Text>
          {line !== length - 1 && <br />}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}

function SingleVariant({ variant, size }) {
  return (
    <VariantBox
      sx={{
        gridColumn: size === 'wide' ? 'text' : 'middle',
        marginX: [3, null, 0],
      }}
      {...variant}
    />
  );
}

function SingleVariantWithRefunds({ variant, size }) {
  return (
    <>
      <VariantBox
        sx={{
          gridColumn: 'content / left',
          marginLeft: 3,
          marginRight: [3, null, 0],
        }}
        {...variant}
      />
      <Refunds
        sx={{
          gridColumn: 'middle / content',
          marginLeft: [3, null, 0],
          marginRight: 3,
        }}
      />
    </>
  );
}

function TwoVariants({ variants, size }) {
  return (
    <>
      <VariantBox
        sx={
          size === 'wide'
            ? {
                gridColumn: ['content', null, 'content / center'],
                marginLeft: [3, null, 4],
                marginRight: 3,
                marginBottom: [3, null, 0],
              }
            : {
                gridColumn: ['content', 'content / center'],
                marginLeft: [3, null, 4],
                marginRight: 3,
                marginBottom: [3, 0],
              }
        }
        {...variants[0]}
      />
      <VariantBox
        sx={
          size === 'wide'
            ? {
                gridColumn: ['content', null, 'center / content'],
                marginLeft: 3,
                marginRight: [3, null, 4],
              }
            : {
                gridColumn: ['content', 'center / content'],
                marginLeft: 3,
                marginRight: [3, null, 4],
              }
        }
        {...variants[1]}
      />
    </>
  );
}
function TwoVariantsWithRefunds({ variants, size }) {
  return (
    <>
      <TwoVariants variants={variants} size={size} />
      <Refunds
        sx={{
          gridColumn: ' content',
          marginX: [3, null, 4],
          marginTop: size === 'wide' ? [3, null, 0] : [3, 0],
        }}
      />
    </>
  );
}

function VariantBox({ title, price, unit, ...rest }) {
  return (
    <Box
      sx={{
        backgroundColor: 'accent',
        color: 'accent.contrast',
        textAlign: 'center',
        paddingX: 3,
        paddingY: 4,
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      {...rest}
    >
      {title && (
        <Paragraph sx={{ margin: [0, 0] }}>
          <Lines>{title}</Lines>
        </Paragraph>
      )}
      {price && <Paragraph variant="styles.h1">€ {price}</Paragraph>}
      {unit && (
        <Paragraph sx={{ margin: [0, 0] }}>
          <Lines>{unit}</Lines>
        </Paragraph>
      )}{' '}
    </Box>
  );
}

function Refunds({ ...rest }) {
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        paddingY: 4,
        paddingRight: [null, '25%', 0, '25%'],
      }}
      {...rest}
    >
      <Heading variant="headingSmall" sx={{ padding: [3, 4] }}>
        Refundierung durch Ihre Krankenkasse
      </Heading>
      <Paragraph sx={{ paddingX: [3, 4] }}>
        Grundvoraussetzung für die Kostenübernahme der jeweiligen Krankenkasse
        einer psychotherapeutischen Behandlung ist eine ärztliche Bestätigung.
        10 Stunden sind bewillungsfrei, danach braucht es ein Antragsformular,
        welches gemeinsam mit meinen Honorarnoten eingereicht werden kann. Bei
        Vorliegen einer krankheitswertigen Diagnose wird (je nach Krankenkasse
        zwischen € 21,80 und € 45,-) ein Teilbetrag refundiert.
      </Paragraph>
    </Box>
  );
}
