// @ts-check
import { graphql } from 'gatsby';
import BlockContent from './BlockContent';

export default function SanitySimplePortableText({ content }) {
  return <BlockContent blocks={content} />;
}

export const fragment = graphql`
  fragment SanitySimplePortableText on SanitySimplePortableText {
    content: _rawContent(resolveReferences: { maxDepth: 10 })
  }
`;
