import Link from '../Link';
import SanityTitle from '../SanityTitle';
import block from './BlockSerializer';
import container from './Container';
import costs from './CostsSerializer';
import discretion from './DiscretionSerializer';
import figure from './FigureSerializer';
import list from './ListSerializer';
import splitBlock from './SplitBlockSerializer';

const serializers = {
  types: {
    figure,
    block,
    discretion,
    costs,
    splitBlock,
    title,
  },
  list,
  container,
  marks: { link, internalLink },
};

export default serializers;

function title({ node }) {
  return <SanityTitle {...node} />;
}

function link({ children, mark }) {
  return <Link href={mark.href}>{children}</Link>;
}
function internalLink({ children, mark }) {
  const slug = mark?.reference?.slug?.current
  if(!slug) return children
  return <Link href={`/${slug}`}>{children}</Link>;
}
