import { graphql, useStaticQuery } from 'gatsby';
import Helmet from 'react-helmet';

/**
 *
 * @param {object} props
 * @param {string} [props.description]
 * @param {string} [props.lang]
 * @param {string[]} [props.meta]
 * @param {string} [props.title]
 * @returns
 */
export default function SEO({
  description,
  lang = 'de',
  meta,
  keywords,
  title,
}) {
  const { site } = useStaticQuery(detailsQuery);
  const metaDescription = description || site?.description || '';
  const siteTitle = site?.title || '';
  const siteAuthor = site?.author?.name || '';
  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={title === siteTitle ? '%s' : `%s | ${siteTitle}`}
    >
      <meta name="description" content={metaDescription} />
    </Helmet>
  );
}

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site: sanitySiteSettings {
      title
      description
      keywords
      # author {
      #   name
      # }
    }
  }
`;
