import { graphql } from 'gatsby';
import { Box, Button, Flex, Heading } from 'theme-ui';
import BlockContent from './BlockContent';
import Link from './Link';
import { Caption, Figure, Image } from './SanityFigure';
import SanitySimplePortableText from './SanitySimplePortableText';

const margins = { large: 6, regular: 5, small: 4, none: 0, undefined: 0 };

export default function SanitySplitBlock({ content, marginTop, marginBottom }) {
  return (
    <>
      {marginTop && marginTop !== 'none' && (
        <Box sx={{ gridColumn: 'full', marginTop: margins[marginTop] }} />
      )}
      {content?.map((block, index) => (
        <SplitContent key={block._key} node={block} isLeft={index % 2 === 0} />
      ))}
      {marginBottom && marginBottom !== 'none' && (
        <Box sx={{ gridColumn: 'full', marginBottom: margins[marginBottom] }} />
      )}
    </>
  );
}

function SplitContent({ node, isLeft, ...rest }) {
  // const gridColumn = isLeft ? ["full", null, "full-start / center"] : ["full", null, "center / full-end"];
  const gridColumn = ['full', null, 'span 4'];
  if (
    node.__typename === 'SanitySimplePortableText' ||
    node._type === 'simplePortableText'
  ) {
    return (
      <SplitText node={node} isLeft={isLeft} sx={{ gridColumn }} {...rest} />
    );
  }

  if (node.__typename === 'SanityFigure' || node._type === 'figure') {
    return <SplitImage node={node} sx={{ gridColumn }} {...rest} />;
  }

  if (
    node.__typename === 'SanityCallToAction' ||
    node._type === 'callToAction'
  ) {
    return (
      <SplitCallToAction
        node={node}
        isLeft={isLeft}
        sx={{ gridColumn }}
        {...rest}
      />
    );
  }

  return null;
}

function SplitText({ node, isLeft, ...rest }) {
  return (
    <Flex
      variant={`styles.block.${node.variant}`}
      sx={{
        flexDirection: 'column',
        justifyContent: 'flex-end',
        backgroundColor: 'primary.100',
        paddingY: [4, 3],
      }}
      {...rest}
    >
      <Box
        sx={{
          maxWidth: theme => 3 * theme.sizes?.column,
          marginLeft: isLeft && 'auto',
        }}
      >
        <SanitySimplePortableText content={node.content} />
      </Box>
    </Flex>
  );
}

function SplitCallToAction({ node, isLeft, ...rest }) {
  return (
    <Box
      variant={`styles.block.${node.variant}`}
      sx={{ padding: [3, 4] }}
      {...rest}
    >
      <Flex
        sx={{
          gap: 3,
          alignItems: 'center',
          justifyContent: 'space-between',
          maxWidth: theme => 3 * theme.sizes?.column,
          marginLeft: isLeft ? [null, null, 'auto'] : 0,
          textAlign: isLeft ? [null, null, 'right'] : 'left',
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Heading variant="styles.h4" as="h4">
            {node.title}
          </Heading>
          <BlockContent blocks={node.content} />
        </Box>
        {node.buttonText && (
          <Button
            as={Link}
            variant={`styles.block.${node.variant}.button`}
            to={node.buttonAction}
          >
            {node.buttonText}
          </Button>
        )}
      </Flex>
    </Box>
  );
}

function SplitImage({ node, isLeft: _, ...rest }) {
  // const width = node.asset?.metadata?.dimensions?.width || 2000;
  return (
    <Figure
      sx={{
        paddingY: 0,
        overflow: 'hidden',
        backgroundColor: 'primary.200',
        '> div': {
          height: '100%',
          maxHeight: ['100vw', null, 'initial', '50vw'],
        },
      }}
      {...rest}
    >
      <Image
        asset={node.asset}
        alt={node.alt}
        objectFit="cover"
        objectPosition="50% 50%"
        sizes="(min-width: 40em) 50vw, 100vw"
        sx={{ margin: 'auto' }}
      />
      <Caption>{node.caption}</Caption>
    </Figure>
  );
}

export const fragment = graphql`
  fragment SanityCallToAction on SanityCallToAction {
    variant
    title
    buttonText
    buttonAction
    content: _rawContent(resolveReferences: { maxDepth: 10 })
  }

  fragment SanitySplitBlock on SanitySplitBlock {
    content {
      __typename
      ... on SanitySimplePortableText {
        _key
      }
      ... on SanityFigure {
        _key
      }
      ... on SanityCallToAction {
        _key
      }
      ...SanitySimplePortableText
      ...SanityFigure
      ...SanityCallToAction
    }
  }
`;
