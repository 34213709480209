import SanityFigure from '../SanityFigure';
const paddingY = { large: 5, regular: 4, small: 3, none: 0, undefined: 5 };
const margins = { large: 6, regular: 5, small: 4, none: 0, undefined: 5 };

export default function FigureSerializer({ node }) {
  if (!node.asset) return null;
  return (
    <SanityFigure
      {...node}
      maxWidth={1088}
      sx={{
        gridColumn: 'full',
        backgroundColor: 'white',
        paddingY: paddingY[node.paddingY],
        marginTop: margins[node.marginTop],
        marginBottom: margins[node.marginBottom],
      }}
    />
  );
}
